import 'whatwg-fetch'

export const API_ORIGIN = process.env.API_ORIGIN
const NETGEAR_ACCOUNT_ORIGIN = process.env.NETGEAR_ACCOUNT_ORIGIN
const CLIENT_ID = process.env.CLIENT_ID

export default function fetchWrapper (url, data = {}) {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('userToken')
    const refresh_token = localStorage.getItem('refreshtoken')
    const headers = new Headers({ 'Content-Type': 'application/json' })

    const method = data.method || 'GET'
    const mode = 'cors'
    let fullUrl
    if (url.startsWith(`${NETGEAR_ACCOUNT_ORIGIN}`)) {
      fullUrl = url
    } else if (url.startsWith('/')) {
      fullUrl = `${API_ORIGIN}${url}`
      if (token) headers.append('Authorization', `Token ${token}`)
    } else {
      fullUrl = `${API_ORIGIN}/production/${url}`
      if (token) headers.append('Authorization', `Token ${token}`)
    }
    if (fullUrl.startsWith(`${NETGEAR_ACCOUNT_ORIGIN}/api/getAccessToken`)) {
      headers.append('Authorization', `Bearer ${refresh_token}`)
      headers.append('appkey', CLIENT_ID)
    } else if (fullUrl.startsWith(`${NETGEAR_ACCOUNT_ORIGIN}/api/oauth/token`)) {
      headers.append('Content-Type', 'application/json')
    } else if (fullUrl.startsWith(`${NETGEAR_ACCOUNT_ORIGIN}/api/logoutUser`)) {
      headers.append('Authorization', `Bearer ${refresh_token}`)
      headers.append('appkey', CLIENT_ID)
      headers.append('Content-Type', 'application/json')
    }

    return fetch(fullUrl, { 
      headers, method, mode, body: JSON.stringify(data.body)
    }).then(response => {
      if (response.status >= 400) {
        response.json().then(parsed => reject(parsed))
      } else {
        resolve(response.json())
      }
    })
  })
}
