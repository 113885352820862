import fetchWrapper from '../utils/FetchWrapper'

const NETGEAR_ACCOUNT_ORIGIN = process.env.NETGEAR_ACCOUNT_ORIGIN

export function getAccessToken () {
    return fetchWrapper(`${NETGEAR_ACCOUNT_ORIGIN}/api/getAccessToken`)
}

export function getAccessTokenByOTC(authorizationCode) {
    return fetchWrapper(`${NETGEAR_ACCOUNT_ORIGIN}/api/oauth/token?code=${authorizationCode}`)
}

export function logout() {
    return fetchWrapper(`${NETGEAR_ACCOUNT_ORIGIN}/api/logoutUser`)
}
