import { getAccessToken, logout, getAccessTokenByOTC } from '../api/onecloud'
import {createBrowserHistory} from "history";
import {logoutUser} from "../actions/AuthActions";

const history = createBrowserHistory({forceRefresh:true})

const LOGOUT_REDIRECT_URL = process.env.LOGOUT_REDIRECT_URL
const NETGEAR_ACCOUNT_ORIGIN = process.env.NETGEAR_ACCOUNT_ORIGIN
const CLIENT_ID = process.env.CLIENT_ID
const AUTHORIZATION_CODE = 'code'

function refreshToken(store, id) {
    getAccessToken().then(response => {
        const token = response.data.accessToken
        localStorage.setItem('userToken', token)
    }).catch(e => {
        if (e.meta && e.meta.code === 400) {
            store.dispatch(logoutUser())
            clearInterval(id)
        }
    })
}

export function updateAccessToken(store) {
    const refreshTime = Number(localStorage.getItem('exp')) ;
    const id = setInterval(() => refreshToken(store, id), 1000 * 60 * refreshTime - 2);
    refreshToken(store, id)
}

export function getLoginUrl() {
    return `${NETGEAR_ACCOUNT_ORIGIN}/login/meural?redirectUrl=${window.location.origin}&clientId=${CLIENT_ID}`
}

export function parseJwt(token) {

    try {
        const data = JSON.parse(atob(token.split(".")[1]));
        const expiredTime_in_second = Number(data.exp)
        const interval_in_msec = expiredTime_in_second * 1000 - Date.now()
        const interval_in_minutes = interval_in_msec/1000/60
        const value = Math.floor(interval_in_minutes)
        return value
    } catch (e) {
        return null;
    }
}

export function getAuthorizationCode(loginUserWithToken) {
    let urlObj = new URL(window.location.href);
    let exist = urlObj.searchParams.has(AUTHORIZATION_CODE);
    let value = exist ? urlObj.searchParams.get(AUTHORIZATION_CODE) : null;
    if (value) {
        window.history.replaceState(null, '', '/')
        getAccessTokenByOTC(value).then(response =>{
            if (response.message == "Success") {
                const accessToken = response.data.accessToken
                const refreshToken = response.data.refreshToken
                const data = parseJwt(accessToken)
                localStorage.setItem('userToken', accessToken)
                localStorage.setItem('refreshtoken', refreshToken)
                if (data) {
                    localStorage.setItem('exp', data)
                    loginUserWithToken()
                }
            }
        }).catch(err => {
            loginUserWithToken()
        })
    } else {
        loginUserWithToken()
    }
}

export function netgearAccountPortallogout(dispatch) {
    return logout().then(r => {
        if (!r.meta || r.meta.code !== 200) {
            localStorage.removeItem('refreshtoken')
            localStorage.removeItem('exp')
        } else {
            localStorage.removeItem('refreshtoken')
            localStorage.removeItem('exp')
            window.location.href = `${NETGEAR_ACCOUNT_ORIGIN}/logout?redirectUrl=${LOGOUT_REDIRECT_URL}`
        }
    }).catch(e => {
        localStorage.removeItem('refreshtoken')
        localStorage.removeItem('exp')
    })
}

