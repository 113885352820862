import * as constants from '../constants/AuthConstants'
import * as API from '../api/Auth'
import {netgearAccountPortallogout} from "../utils/cognito";

export const loginUserWithToken = () => dispatch => {
  return API.getUser().then(payload => dispatch({
    type: constants.RECEIVE_USER, payload
  })).catch(error => {
    dispatch({ type: constants.RECEIVE_AUTH_ERROR, error })
  })
}


export const logoutUser = () => dispatch => {
  localStorage.removeItem('userToken')
  netgearAccountPortallogout(dispatch)
}
