import React, { Component } from 'react'
import Overview from './Overview'
import { Button } from 'reactstrap'
import DeviceTable from './DeviceTable'
import Controls from './Controls'
import Message from './Message'
import * as API from '../api/Production'
import createStore from "../store/Store";
import {updateAccessToken} from "../utils/cognito";



export default class PrimaryLayout extends Component {
  constructor (props) {
    super(props)
    const store = createStore()
    updateAccessToken(store)

    this.state = {
      meta: {
        new: 0,
        inProgress: 0,
        failed: 0,
        done: 0,
        recovered: 0
      },
      filters: {
        productKey: '',
        fromDate: '',
        toDate: '',
        productionStatus: ''
      },
      devices: [],
      currentPage: 0,
      isLast: false,
      errorMessage: '',
      successMessage: ''
    }

    this.clearMessage = this.clearMessage.bind(this)
    this.fetchDevices = this.fetchDevices.bind(this)
    this.fixDevice = this.fixDevice.bind(this)
    this.updateDevice = this.updateDevice.bind(this)
    this.handleFilterChange = this.handleFilterChange.bind(this)
  }

  componentDidMount () {
    this.fetchDevices()
  }

  clearMessage (type) {
    this.setState({ [type]: ''})
  }

  fetchDevices (e, page = 1) {
    e && e.preventDefault()

    API.fetchDevices({ ...this.state.filters, page })
      .then((payload) => {
        const devices = page === 1
          ? payload.data : this.state.devices.concat(payload.data)
        this.setState({
          currentPage: page,
          meta: payload.meta,
          devices,
          isLast: payload.isLast
        })
      })
      .catch(error => {
        this.setState({ errorMessage: this.receiveErrors(error) })
      })
  }

  fixDevice (id) {
    API.fixDevice(id)
      .then(payload => {
        let devices = [].concat(this.state.devices)
        const idx = devices.findIndex(device => device.id === id)
        if (idx !== -1) devices.splice(idx, 1, payload.data)
        const success = payload.data.productionStatus === 'in_progress'
        const newFailedCount = success
          ? this.state.meta.failed - 1 : this.state.meta.failed
        const newInProgressCount = success
          ? this.state.meta.inProgress + 1 : this.state.meta.inProgress
        this.setState({
          devices,
          meta: Object.assign({}, this.state.meta, {
            failed: newFailedCount,
            inProgress: newInProgressCount
          }),
          successMessage: 'Fix in progress'
        })
      })
      .catch(error => {
        this.setState({ errorMessage: this.receiveErrors(error) })
      })
  }

  updateDevice (id) {
    API.updateDevice(id)
    .then(() => this.setState({ successMessage: 'Device updated' }))
      .catch(error => {
        this.setState({ errorMessage: this.receiveErrors(error) })
      })
  }

  handleFilterChange (filterParams) {
    const mergedFilters = Object.assign({}, this.state.filters, filterParams)
    this.setState({ filters: mergedFilters })
  }

  receiveErrors (e) {
    let vals = []
    if (e) {
      if (typeof e !== 'object') {
        vals.push('Error: ' + e)
      } else {
        let keys = Object.keys(e)
        while (keys.length) {
          let currKey = keys.pop()
          vals = vals.concat(this.receiveErrors(e[currKey]))
        }
      }
    }
    return vals.join(', ')
  }

  render () {
    let loadMoreBtn
    if (this.state.isLast !== true) {
      loadMoreBtn = (
        <Button
          onClick={e => this.fetchDevices(e, this.state.currentPage + 1)}>
          Load more
        </Button>
      )
    }

    return (
      <div className='mt-3'>
        <Message
          errorMessage={this.state.errorMessage}
          successMessage={this.state.successMessage}
          clearMessage={this.clearMessage} />
        <Overview {...this.state.meta} />
        <Controls
          handleChange={this.handleFilterChange}
          filters={this.state.filters}
          fetchDevices={this.fetchDevices} />
        <DeviceTable
          devices={this.state.devices}
          fixDevice={this.fixDevice}
          updateDevice={this.updateDevice} />
        {loadMoreBtn}
      </div>
    )
  }
}
